import React from "react";
import WhatsApp from "../../assets/images/whattsApp.svg";

export const FloatingButton = ({ platform, url, bottom = "20px" }) => {
  // Define styles for button positioning

  //   const buttonStyle = positionStyles[position] || positionStyles.bottomRight;

  // Define icon based on platform
  const platformIcon = {
    whatsapp: WhatsApp, // replace with WhatsApp SVG icon if preferred
    instagram:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png", // replace with Instagram SVG icon if preferred
  };

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: bottom,
        right: "20px",
        zIndex: "1000",
        backgroundColor: platform === "whatsapp" ? "#25D366" : "#E1306C",
        color: "white",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        textDecoration: "none",
        fontSize: "24px",
        cursor: "pointer",
      }}
    >
      {
        <img
          style={{ width: "100%" }}
          src={platformIcon[platform]}
          alt={platform}
        />
      }
    </a>
  );
};

export default FloatingButton;
