import "./App.scss";
import { Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop/Shop";
import AboutUs from "./pages/AboutUs/AboutUs";
import ProductPage from "./components/ProductPage/ProductPage";
import SearchProducts from "./components/SearchProducts/SearchProducts";
import SortedProducts from "./components/SortedProducts/SortedProducts";
import Footer from "./components/Footer/Footer";
import Admin from "./pages/Admin/Admin";
import Inventory from "./adminComponents/Inventory/Inventory";
import BrandCategories from "./adminComponents/BrandCategories/BrandCategories";
import NewProductForm from "./adminComponents/Inventory/NewProductForm/NewProductForm";
import EditInventoryItem from "./adminComponents/Inventory/EditInventoryItem/EditInventoryItem";
import NewBrandForm from "./adminComponents/BrandCategories/NewBrandForm/NewBrandForm";
import EditBrandItem from "./adminComponents/BrandCategories/EditBrandItem/EditBrandItem";
import JewelleryCategories from "./adminComponents/JewelleryCategories/JewelleryCategories";
import NewJewelleryForm from "./adminComponents/JewelleryCategories/NewJewelleryForm/NewJewelleryForm";
import EditJewelleryItem from "./adminComponents/JewelleryCategories/EditJewelleryItem/EditJewelleryItem";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./adminComponents/PrivateRoute";
import Login from "./adminComponents/Login";
import Contact from "./pages/Contact/Contact";
import Payments from "./pages/Payments/Payments";
import SellYours from "./pages/SellYours/SellYours";
import Terms from "./pages/Terms/Terms";
import Sale from "./pages/Sale/Sale";
import FloatingButton from "./components/FloatingButton/FloatingButton";

function App() {
  return (
    <div className="App">
      <Header />

      <AuthProvider>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/sale" component={Sale}></Route>
          <Route exact path="/shop" component={Shop}></Route>
          <Route exact path="/about-us" component={AboutUs}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/payments" component={Payments}></Route>
          <Route exact path="/terms&conditions" component={Terms}></Route>
          <Route exact path="/sell-yours" component={SellYours}></Route>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/bj-admin" component={Admin} />
          <PrivateRoute
            exact
            path="/bj-admin/inventory"
            component={Inventory}
          />
          <PrivateRoute
            path="/bj-admin/inventory/new-product"
            component={NewProductForm}
          ></PrivateRoute>
          <Route
            path="/bj-admin/inventory/edit/:id"
            render={(routerProps) => {
              return <EditInventoryItem {...routerProps} />;
            }}
          />
          <PrivateRoute
            exact
            path="/bj-admin/brands"
            component={BrandCategories}
          ></PrivateRoute>
          <PrivateRoute
            path="/bj-admin/brands/new-brand"
            component={NewBrandForm}
          ></PrivateRoute>

          <PrivateRoute
            path="/bj-admin/brands/edit/:id"
            component={EditBrandItem}
          />
          <PrivateRoute
            exact
            path="/bj-admin/jewellery"
            component={JewelleryCategories}
          ></PrivateRoute>
          <PrivateRoute
            path="/bj-admin/jewellery/new-category"
            component={NewJewelleryForm}
          ></PrivateRoute>
          <PrivateRoute
            path="/bj-admin/jewellery/edit/:id"
            component={EditJewelleryItem}
          />
          <PrivateRoute
            path="/bj-admin/brand-category"
            component={BrandCategories}
          ></PrivateRoute>
          <Route
            path="/shop/:brand/:jewellery/product/:id"
            component={ProductPage}
          />
          <Route
            path="/shop/:brand"
            render={(routerProps) => {
              return (
                <SortedProducts
                  {...routerProps}
                  sort={routerProps.match.params.brand}
                />
              );
            }}
          />
          <Route
            path="/shop/:jewellery"
            render={(routerProps) => {
              return (
                <SortedProducts
                  {...routerProps}
                  sort={routerProps.match.params.jewellery}
                />
              );
            }}
          />
          <Route
            path="/search/:query"
            render={(routerProps) => {
              return (
                <SearchProducts
                  {...routerProps}
                  sort={routerProps.match.params.query}
                />
              );
            }}
          />
        </Switch>
      </AuthProvider>
      <FloatingButton platform="whatsapp" url="https://wa.me/7566209143" />
      <FloatingButton
        platform="instagram"
        url="https://www.instagram.com/brandedjewelleryuk/?hl=en"
        bottom="80px"
      />
      <Footer />
    </div>
  );
}

export default App;
